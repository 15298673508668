import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles, Typography } from '@material-ui/core';
import ClinicLocation from './ClinicLocation';
import { thanksPageStyles } from './styles';
import ClinicApi from '../../api/clinicApi';
import { LAST_CLINIC_ID } from '../../constants/storageKeys';

function ThanksPage({ classes }) {
    const organisation = useSelector(state => state.auth.organisation);
    const [defaultClinic, setDefaultClinic] = useState(null);

    useEffect(() => {
        const getClinic = async () => {
            const clinics = await ClinicApi.listPublicClinics();
            const lastClinicId = localStorage.getItem(LAST_CLINIC_ID);
            if (lastClinicId) {
                const clinic = clinics.find(clinic => clinic.id === lastClinicId);
                if (clinic) {
                    setDefaultClinic(clinic);
                    return;
                }
            }
            setDefaultClinic(clinics[0]);
        };

        getClinic();
    }, []);

    return (
        <div className={classes.thanksPageRoot}>
            <div className={classes.container}>
      
                <div 
                    className={classes.thankYouTextStyles}
                    dangerouslySetInnerHTML={{ __html: organisation.thankYouText }}
                />
                
            </div>
            <div className={classes.container}>
                <Typography variant="h4" component="h2">
                    How to find us
                </Typography>
                {defaultClinic && <ClinicLocation defaultClinic={defaultClinic} />}
            </div>
        </div>
    );
}

ThanksPage.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(thanksPageStyles)(ThanksPage);
